/**
 * 获取当前配置的站点元信息,因为使用了window变量，不支持服务端渲染
 * 若要支持可以把windo变量移动到componentDidMount生命周期里去
 */

import { useStaticQuery, graphql } from "gatsby"
export const useSiteMetadata = () => {
  const { data: {sites} } = useStaticQuery(
    graphql`
      query {
        data: allSiteMetaDatasJson {
          sites: nodes {
            vendor_name,
            title
            name
            description
            author
            bd_email
            bd_phone
            bd_service_time
            bd_address
            head_logo
            foot_logo
            copy_right_date
            copy_right_company
            site_case_number
            support_trail
            support_console
            show_ios_download
            support_ios_qrcode
            support_android_qrcode
          }
        }
      }
    `
  );

  const site = sites.find(item => item.vendor_name.indexOf(process.env.GATSBY_VENDOR_NAME) > -1)
  return site
}
