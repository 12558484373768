/**
 * 获取首页图片
 */

import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

export const useStaticQueryIndexImages = () => {
  const intl = useIntl()
  const imgData = useStaticQuery(graphql`
    query {
      section1Png: file(relativePath: { eq: "home_section_1.png" }) {
        childImageSharp {
          fixed(width: 685, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      section4Png1: file(relativePath: { eq: "home_section4_efficient.png" }) {
        childImageSharp {
          fixed(width: 290, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      section4Png2: file(relativePath: { eq: "home_section4_easyuse.png" }) {
        childImageSharp {
          fixed(width: 290, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      section4Png3: file(relativePath: { eq: "home_section4_intelligent.png" }) {
        childImageSharp {
          fixed(width: 290, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      section4Png4: file(relativePath: { eq: "home_section4_open.png" }) {
        childImageSharp {
          fixed(width: 290, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      section3Png1: file(relativePath: { eq: "p1-0@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 876, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section3Png2: file(relativePath: { eq: "zh_p2-0@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section3Png2zh_tw: file(relativePath: { eq: "zh-tw_p2-0@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section3Png3: file(relativePath: { eq: "zh_p3-0@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section3Png3zh_tw: file(relativePath: { eq: "zh-tw_p3-0@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section3Png4: file(relativePath: { eq: "zh_p4-0@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section3Png4zh_tw: file(relativePath: { eq: "zh-tw_p4-0@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section3Png5: file(relativePath: { eq: "zh_p5-0@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section3Png5zh_tw: file(relativePath: { eq: "zh-tw_p5-0@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // 把-替换成_,例如zh-tw改成zh_tw
  const locale = intl.locale.replace('-', '_')

  // 获取所有图片（如果有对应的国际化图片则取对应的国际化图片，但是图片名称不做国际化）
  const imgs = Object.keys(imgData).reduce((acc, cur) => {
    // 当前图片是否为国际化图片,国际化图片的后缀是以当前locale结尾的，且中划线改成下划线
    const isLocaleImg = cur.endsWith(locale)

    // 去掉可能存在的locale后缀
    const key = cur.replace(locale, '')

    if (isLocaleImg) {
      // 如果是国际化图片，则直接取该图片
      acc[key] = imgData[cur]
    } else {
      const hasLocaleImg = !!imgData[key + locale]
      // 非国际化图片且存在对应的国际化图片，则直接跳过
      // 非国际化图片且不存在对应的国际化图片，则选择该图片
      if (!hasLocaleImg) {
        acc[key] = imgData[cur]
      }
    }
    return acc
  }, {})

  return imgs
}
