import { useState, useEffect } from "react"
import { requestDownloadURL } from "../api/index"

interface Params {
  isWin: boolean
  isMac: boolean
  isAndroid: boolean
  isIOS: boolean
}

export default function useGetInvitationDownloadLink({
  isWin,
  isMac,
  isAndroid,
  isIOS,
}: Params) {
  const [downloadLink, setDownloadLink] = useState<
    {
      id: string
      title: string
      platform: "Android" | "macOS" | "Windows"
      version: string
      downloadTips: Array<string>
      downloadLink: string
      QRcodeImageLink: string
      channel: string
    }[]
  >()

  useEffect(() => {
    requestDownloadURL().then(result => {
      setDownloadLink(result.data)
    })
  }, [])

  function getPlatform({
    isWin,
    isMac,
    isAndroid,
  }: {
    isWin: boolean
    isMac: boolean
    isAndroid: boolean
  }) {
    let platform = ""
    console.log("isWin", isWin)
    console.log("isMac", isMac)
    console.log("isAndroid", isAndroid)

    if (isWin) {
      platform = "windows"
    } else if (isMac) {
      platform = "macos"
    } else if (isAndroid) {
      platform = "android"
    } else {
      platform = "ios"
    }

    return platform
  }

  function getDownloadLink() {
    const platform = getPlatform({
      isMac,
      isWin,
      isAndroid,
    })
    const result = downloadLink?.find(element => {
      console.log(
        "getDownloadLink -> platform.toLowerCase()",
        platform.toLowerCase()
      )
      console.log(
        "getDownloadLink -> element.platform.toLowerCase()",
        element.platform.toLowerCase()
      )
      return element.platform.toLowerCase() === platform.toLowerCase()
    })
    const link = result?.downloadLink
    return link
  }

  const link = getDownloadLink()
  return link
}
