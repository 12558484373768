import { get } from "./tool"
import { CommonResultData } from "./type"

interface DataItem {
  id: string
  title: string
  platform: "Android" | "macOS" | "Windows" | "ios"
  version: string
  downloadTips: Array<string>
  downloadLink: string
  QRcodeImageLink: string
  channel: string
}

interface ResultData extends CommonResultData {
  data: Array<DataItem>
}

export async function requestDownloadURL(
  channel = process.env.GATSBY_VENDOR_NAME
) {
  try {
    const result = await get("https://console.teampro.work/api/download/list", {
      channel,
    })

    const resultData: ResultData = result.data

    if (resultData.code !== 0) {
      throw new Error(resultData.errmsg)
    }

    console.log(`api/requestDownloadURL.ts${resultData}`)

    return resultData
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error("other error")
    }
  }
}
